import React, {useEffect, useState} from "react";
import axios from "axios";
import FairDetailsPageContent from "./FairDetailsPageContent";
import { FairInfoBar } from "../../FairInfoBar";
import { handleGenericErrors, handleGenericSuccess } from "../../../utils/ErrorUtility";
import { updateUserAccountId, updateUserDollarBalance, updateUserFairId, updateUserFairStart, updateUserFairEnd, updateUserFlyerCount, updateUserLoginStatus, updateUserSchoolName, updateUserSetUpDate, updatePageType } from "../../../DumbleData";

interface Props {
	language: string;
}

const FairDetailsPage = ({language}: Props) => {
	const [fairInfo, setFairInfo] = useState<any>();
	const [organization, setOrganization] = useState<any>();
	const [scholasticDollars, setScholasticDollars] = useState<any>();
	const [chairperson, setChairperson] = useState<any>();
	const [consultant, setConsultant] = useState<any>();
	const [homepage, setHomepage] = useState<any>();
	const [coChairs, setCoChairs] = useState();
	const [salesHistory, setSalesHistory] = useState<any>();
  	const [user, setUser] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

  	useEffect(() => {
		const getFairSettingsPageData = async () => {
				try {
					await axios
						.get(
							`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/settings`,
							{
								withCredentials: true,
							}
						)
						.then((response) => {
							if (response.status === 200) {
								const { fairInfo, organization, scholasticDollars, chairperson, consultant, homepage, salesHistory, user } = response.data;
								if ( fairInfo ) setFairInfo(fairInfo);
								if ( organization ) setOrganization(organization);
								if ( scholasticDollars ) setScholasticDollars(scholasticDollars);
								if ( chairperson ) setChairperson(chairperson);
								if ( consultant ) setConsultant(consultant);
								if ( homepage ) setHomepage(homepage);
								if ( salesHistory ) setSalesHistory(salesHistory);
                				if ( user ) setUser(user);
								updateUserFairId(fairInfo.id);
								updateUserAccountId(organization.bookfairAccountId);
								updateUserLoginStatus(true);
								updatePageType("setup");
								updateUserSetUpDate(fairInfo.setup);
								updateUserFairStart(fairInfo.start);
								updateUserFairEnd(fairInfo.end);
								updateUserDollarBalance(scholasticDollars.balance);
								updateUserSchoolName(fairInfo.name);
								updateUserFlyerCount(fairInfo.fliers.fifthAndBelow, fairInfo.fliers.sixthAndAbove);
								window.scrollTo({
									top: 0,
									left: 0
								});
							} 
							else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							handleGenericErrors(err);
						});
				} catch (err: any) {
					handleGenericErrors(err);
				} finally {
					setIsLoading(false);
				}
		};
		getFairSettingsPageData();

	}, [
		setFairInfo,
		setOrganization,
    	setScholasticDollars,
		setConsultant,
    	setHomepage,
		setCoChairs,
		setSalesHistory,
		setUser
	]);

	

	return (
		<>
		{!isLoading && (<>
			<FairInfoBar
				language={language}
				enableSwitch={user?.enableSwitch}
				fairInfoData={fairInfo}
				organizationData={fairInfo}
				pageType={"fair-details"}
			/>
			<FairDetailsPageContent
				fairInfo={fairInfo}
				organization={organization}
                scholasticDollars={scholasticDollars}
                chairperson={chairperson}
				consultant={consultant}
                homepage={homepage}
				coChairs={coChairs}
				language={language}
				salesHistory={salesHistory}
			/> 		
		</>)}
		</>
	);
};

export default FairDetailsPage;
