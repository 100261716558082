import React from "react";
import styled from "styled-components";
import COApdf from "../../../images/COApdf.png";
import COApdf_FR from "../../../images/COApdf_FR.jpg";

interface Props {
	emailedPage?: boolean;
	bogoFair:boolean;
	language: string;
}

const BookFair = ({
	emailedPage,
	bogoFair,
	language
}: Props) => {

	if ( language === "fr" ) {
		return (
			<BookFairContainer>
			<OpeningParagraph>
				<b>Merci d'avoir réservé et d'organiser un Festival du Livre Scholastic! </b> 
				Nous sommes très heureux de
travailler en partenariat avec vous et votre communauté scolaire. Les directives suivantes assureront un
partenariat efficace. Au plaisir de travailler avec vous!
			</OpeningParagraph>
			<BookFairText>
				<p> Comme toujours, Scholastic vous fournit : </p>
				<BookFairList>
					<li>Des livres et des produits éducatifs</li>
					<li>Une équipe de soutien et de service dédiée</li>
					<li>Un portail en ligne rempli de ressources et d'outils de planification</li>
					<li>Une trousse de démarrage contenant du matériel promotionnel</li>
					<li>Un accès aux achats en ligne sur l'ensemble de nos sites Scholastic</li>
					<li>Un système de paiement sans fil qui vous permet d'accepter toutes les principales cartes de
crédit et de débit</li>
				</BookFairList>
				<p>En organisant un Festival du Livre Scholastic, votre école s'engage à :</p>
				<BookFairList>
					<li>
					Utiliser le Festival du Livre Scholastic comme fournisseur et détaillant exclusif de tous les livres,
les articles et le matériel promotionnel durant le Festival du Livre.
					</li>
					<li>
					Stocker et exposer la marchandise, l'argent liquide, les chèques, les appareils de paiement sans
fil et les bordereaux de vente dans un endroit fermé à clé et sécurisé lorsqu'ils ne sont pas utilisés
lors de votre Festival du Livre*.
					</li>
					<li>
					Mettre les produits à la disposition de tou·tes les visiteur·ses du Festival au prix, taxes
comprises, indiqué par Scholastic*.
					</li>
					<li>
					Réemballer tous les produits invendus, les fournitures et les présentoirs en vue de leur
ramassage ou de leur retour*
					</li>
					<li>
					Retourner tous les bordereaux de vente, les dispositifs de paiement sans fil, les produits
invendus, le matériel de marchandisage et les présentoirs au Festival du Livre Scholastic dans les
plus brefs délais à la fin du Festival du Livre.
					</li>
					<li>
					Traiter et soumettre les formulaires financiers du Festival du Livre et le paiement dans les sept
(7) jours suivant la fin du Festival.
					</li>
					<li>
					Consulter les procédures de paiement indiquées sur le formulaire et appliquer les règles de
sécurité suivantes : 
						<BookFairSublist>
							<li>
							Ne pas partager ou distribuer le dispositif de paiement sans fil et les données recueillies
au nom du Festival du Livre Scholastic (numéros de carte, lecteurs de cartes, etc.).
							</li>
							<li>
							Les appareils de paiement sans fil ne doivent pas être utilisés à des fins personnelles; ils
ne doivent être utilisés que pour les transactions effectuées lors du Festival du Livre
Scholastic.
							</li>
							<li>
							Tous les appareils ou reçus de carte de crédit laissés sans surveillance doivent être
placés dans un endroit verrouillé ou sécurisé.
							</li>
							<li>
							L'appareil de paiement sans fil ne doit pas être connecté à des réseaux non autorisés.
							</li>
							<li>
							Les bénévoles doivent faire preuve de discernement en cas de problème, ou contacter la
personne responsable du Festival du Livre ou le service d'assistance téléphonique du
système de paiement pour obtenir de l'aide.
							</li>
						</BookFairSublist>
					</li>
				</BookFairList>
			</BookFairText>
			<BookFairText><i>
				<b><RedText>Remarque : </RedText></b> En vigueur au 1
er août 2024, les ajustements suivants ont été apportés à la structure des gains en
dollars Scholastic, comme indiqué dans le tableau ci-dessous.<br />
Pour plus d'informations, veuillez communiquer avec votre conseiller·ère du Festival du Livre.
				</i></BookFairText>
			{!emailedPage ? <PageBreak/> : ""}
			{!emailedPage ? <StyledCOAImg src={COApdf_FR}/> : ""}
			<ProfitHeading>Structure des gains en dollars Scholastic</ProfitHeading>
			<BookFairText>
				{bogoFair && 
					<>Veuillez noter que les écoles peuvent bénéficier d’un Festival gratuit à partir du troisième Festival organisé au cours de l’année scolaire. Les récompenses doivent alors être échangées en produits directement au Festival et ne peuvent pas être échangées contre des certificats ou de l’argent comptant.</>
				}
				{ !bogoFair && 
					<>Votre école peut choisir de recevoir le pourcentage approprié des ventes nettes de l'événement sous forme de
	produits du Festival, de dollars Scholastic, d'argent comptant ou d'une combinaison de ces options. Le tableau
	ci-dessous présente tous les niveaux de vente et les détails de la structure des gains. Votre formulaire de
	récompenses fournira des calculs pour chaque option afin de faciliter la sélection des récompenses en
	produits, en dollars Scholastic ou en espèces. <b>Les dollars Scholastic obtenus expirent 24 mois après leur
	date d'émission.</b> </>}
			</BookFairText>
			{ bogoFair && 
			<ChartInnerContainer>
			<table>
				<tbody>
					<tr>
						<th className={"row-1"}>Ventes nettes du Festival du Livre (montant après les taxes)</th>
						<th>Produits pris au Festival</th>
						<th>Valeur en argent</th>
					</tr>
					<tr>
						<td className={"row-1"}>1 500 $ ou plus</td>
						<td>20%</td>
						<td>S.O.</td>
					</tr>
					<tr>
						<td className={"row-1"}>Moins de 1 499 $</td>
						<td>N/A</td>
						<td>S.O.</td>
					</tr>
				</tbody>
			</table>
		</ChartInnerContainer>}
			{!bogoFair && <ChartInnerContainer>
				<table>
					<tbody>
						<tr>
							<th className={"row-1"}>Ventes nettes du Festival du Livre
(excluant les taxes) </th>
							<th>Revenus en dollars Scholastic</th>
							<th>Pourcentage en argent comptant*</th>
						</tr>
						<tr>
							<td className={"row-1"}>5 000 $ et plus</td>
							<td>50%</td>
							<td>25%</td>
						</tr>
						<tr>
							<td className={"row-1"}>3 500 $ à 4 999,99 $</td>
							<td>40%</td>
							<td>20%</td>
						</tr>
						<tr>
							<td className={"row-1"}>2 000 $ à 3 499,99 $</td>
							<td>30%</td>
							<td>15%</td>
						</tr>
						<tr>
							<td className={"row-1"}>Moins de 2 000 $</td>
							<td>15%</td>
							<td>S.O.</td>
						</tr>
					</tbody>
				</table>
				<p>** L'option de paiement en argent comptant n'est pas disponible dans la province de Québec.</p>
			</ChartInnerContainer>}
			<ProfitHeading>Frais de livraison et politique d'annulation tardive pour le Festival du Livre Scholastic</ProfitHeading>
			<BookFairText>
			Des frais de livraison standard de 25 $ canadiens seront appliqués à chaque Festival du Livre programmé. Ces
frais seront déduits des dollars Scholastic gagnés une fois le formulaire de récompenses rempli.
			</BookFairText>
			<BookFairText>
			Il est essentiel que tous les Festivals du Livre soient confirmés au moins trois semaines avant la date de
montage prévue, car cela nous laisse le temps de procéder aux derniers préparatifs en ce qui a trait à la
production et à la livraison. Veuillez noter que des frais d'annulation tardive de 100 $ seront appliqués aux
Festivals qui annulent un événement confirmé dans les cinq jours ouvrables précédant la date de mise en
place. Ces frais seront déduits du solde des dollars Scholastic figurant au compte dans les 48 heures suivant
l'annulation.
			</BookFairText>
			<BookFairText>
				<i>Remarque : Si le solde des dollars Scholastic ne peut pas couvrir les frais de livraison ou les frais d'annulation
tardive, une facture sera envoyée par courriel à la personne responsable du Festival du Livre.</i>
			</BookFairText>
		</BookFairContainer>
		);
	}

	return (
		<BookFairContainer>
			<OpeningParagraph>
				<b>Thank you for scheduling and hosting a Scholastic Book Fair! </b> 
				We are so happy to be partnering with you, and your school community. 
				The following guidelines will ensure an effective partnership, and we 
				look forward to working with you. 
			</OpeningParagraph>
			<BookFairText>
				<p> As always, Scholastic will provide you with: </p>
				<BookFairList>
					<li>Books and educational products</li>
					<li>A dedicated support and service team</li>
					<li>An online Host Hub full of planning resources & tools</li>
					<li>Kick-off Kit with promotional materials</li>
					<li>Access to online shopping through all of our Scholastic sites</li>
					<li>A wireless payment system that allows you to accept all major credit and debit cards</li>
				</BookFairList>
				<p>By hosting a Scholastic Book Fair, your school agrees to:</p>
				<BookFairList>
					<li>
					Use Scholastic Book Fairs as the exclusive provider and 
					retailer of all books, merchandise, and promotional materials 
					during the book fair event.
					</li>
					<li>
					Store and display all merchandise, cash, cheques, wireless 
					payment devices, and sale slips in a locked and secure location 
					when not in use at your Book Fair.*
					</li>
					<li>
					Make products available to all qualified event attendees at the 
					tax-inclusive listed price designated by Scholastic.*
					</li>
					<li>
					Repack all unsold products, supplies, and displays for pickup 
					and/or return.*
					</li>
					<li>
					Return all sale slips, wireless payment devices, unsold products, 
					and merchandising materials/displays to Scholastic Book Fairs 
					promptly at the conclusion of the Book Fair.
					</li>
					<li>
					Process and submit the Book Fair financial forms and payment within 
					seven (7) days after the fair has ended.
					</li>
					<li>
					Review the payment procedures indicated on the Worksheet and comply 
					with the following security procedures: 
						<BookFairSublist>
							<li>
							Do not share or distribute the wireless payment device or 
							data taken on behalf of Scholastic Book Fairs (card numbers, 
							card readers, etc.).
							</li>
							<li>
							Wireless payment devices are not to be used for personal 
							use/gain; devices are only to be used for transactions at 
							Scholastic Book Fairs events.
							</li>
							<li>
							All unattended devices or credit card receipts must be in a 
							locked or secure location.
							</li>
							<li>
							Do not connect the wireless payment device to any 
							unauthorized networks.
							</li>
							<li>
							Volunteers should use good and reasonable judgment in the event 
							of any issues or contact the Book Fair Host or the payment system 
							hotline for guidance.
							</li>
						</BookFairSublist>
					</li>
				</BookFairList>
			</BookFairText>
			<BookFairText><i>
				<b><RedText>Please Note: </RedText></b> Effective August 1st 2024, the following 
				adjustments have been made to the Scholastic Dollars Earnings structure, 
				as outlined in the table below. <br/> <br/>
				For further clarity, please reach out to your dedicated Book Fair Consultant.
				</i></BookFairText>
			{!emailedPage ? <PageBreak/> : ""}
			{!emailedPage ? <StyledCOAImg src={COApdf}/> : ""}
			<ProfitHeading>Scholastic Dollars Earning Structure</ProfitHeading>
			<BookFairText>
				{bogoFair && <>Please note that schools are eligible for a BOGO Fair as the third Fair scheduled within the school year. Any earned rewards must be taken in product from the Fair and cannot be applied as a certificate or taken in cash.</>}
				{!bogoFair && <>Your school may opt to take the appropriate percentage of the event's 
					net sales in product from the Fair, Scholastic Dollars, cash, or a 
					combination of these options. See the chart below for all sales levels 
					and earning structure details. Your Rewards Worksheet will provide 
					calculations of each option to facilitate the selection of product 
					rewards, Scholastic Dollars, or cash. <b>Earned Scholastic Dollars 
					expire 24 months from the date of issue.</b></>}
			</BookFairText>
			{ bogoFair &&
			<ChartInnerContainer>
				<table>
					<tbody>
						<tr>
							<th className={"row-1"}>Book Fair Net Sales (tax excluded)</th>
							<th>Product</th>
							<th>Cash</th>
						</tr>
						<tr>
							<td className={"row-1"}>More than $1,500</td>
							<td>20%</td>
							<td>N/A</td>
						</tr>
						<tr>
							<td className={"row-1"}>Less than $1,499</td>
							<td>N/A</td>
							<td>N/A</td>
						</tr>
					</tbody>
				</table>
			</ChartInnerContainer>}
			{ !bogoFair &&
			<ChartInnerContainer>
				<table>
					<tbody>
						<tr>
							<th className={"row-1"}>Book Fair Net Sales (tax excluded)</th>
							<th>Scholastic Dollars Earnings</th>
							<th>Cash %*</th>
						</tr>
						<tr>
							<td className={"row-1"}>$5000+</td>
							<td>50%</td>
							<td>25%</td>
						</tr>
						<tr>
							<td className={"row-1"}>$3500-$4999.99</td>
							<td>40%</td>
							<td>20%</td>
						</tr>
						<tr>
							<td className={"row-1"}>$2000-$3499.99</td>
							<td>30%</td>
							<td>15%</td>
						</tr>
						<tr>
							<td className={"row-1"}>&lt; $2000.00</td>
							<td>15%</td>
							<td>N/A</td>
						</tr>
					</tbody>
				</table>
				<p>*Cash option not available in the province of Quebec.</p>
			</ChartInnerContainer>}
			<ProfitHeading>Scholastic Book Fair Delivery Fee and Late Cancellation Policy</ProfitHeading>
			<BookFairText>
			A standard Delivery Fee of $25.00CAD will be applied to each scheduled 
			In-School Book Fair. This fee will be decremented from the earned 
			Scholastic Dollars upon completion of the worksheet.
			</BookFairText>
			<BookFairText>
				It is critical that all Scholastic Book Fairs be confirmed a minimum 
				of 3 weeks prior to the Fair Set-Up date, as this allows us time for 
				production and final delivery preparations. Please note that a Late 
				Cancellation Fee of $100.00CAD will be applied to those In-School 
				Fairs who cancel a confirmed Book Fair within 5 business days of the 
				Set-Up Date. This fee will be decremented from the Scholastic Dollars 
				balance on the account within 48 hours of cancellation.
			</BookFairText>
			<BookFairText>
				Please Note: If the Scholastic Dollars balance cannot accommodate the 
				Delivery Fee or Late Cancellation Fee, an invoice will be generated and 
				supplied via email to the Book Fair Host.
			</BookFairText>
		</BookFairContainer>
	);
};

const StyledCOAImg = styled.img`
	width: 50%;
	padding-bottom: 25px;
	align-self: center;
`;	

const RedText = styled.span`
	color: #E81111;
`;

const PageBreak = styled.span`
	height: 500px;
`;

const BookFairContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 6.5in;
`;

const OpeningParagraph = styled.p`
	margin-top: 20px;
	font-size: 10px;
`;

const BookFairText = styled.div`
	font-size: 9px;
	padding: 10px 0;
	p {
		padding: 10px 0;
	}
`;

const BookFairList = styled.ul`
	list-style-type: disc; /* Change the bullet point to a disc */
	margin: 0; /* Remove default margin */
	padding-left: 0; /* Remove default padding */
	li {
		margin-left: 0.25in;
	}
`;

const BookFairSublist = styled.ul`
	list-style-type: circle; /* Change the bullet point to a circle */
	margin: 0; /* Remove default margin */
	padding-left: 1.5em; /* Add left padding for indentation */
`;

const ProfitHeading = styled.div`
	font-size: 10px;
	font-weight: 700;
`;

const ChartInnerContainer = styled.div`
	width: 100%;
	font-size: 9px;
	margin-bottom: 20px;
	table {
		width: 100%;
		border-collapse: collapse;
		text-align: center;
		margin-bottom: 10px;
	}
	th {
		text-align: center;
		padding: 3px 0px 10px;
		border-right: 1px solid black;
		border-bottom: 1px solid black;
		border-top: 1px solid black;
		font-weight: 700;

		&.row-1 {
			border-left: 1px solid black;
		}
	}
	td {
		padding: 3px 0px 10px;
		border-right: 1px solid black;
		border-bottom: 1px solid black;

		&.row-1 {
			border-left: 1px solid black;
		}
	}
`;

export default BookFair;
