import React, { useEffect, useState } from "react";
import { ConfirmYourFairContent } from "./ConfirmYourFairContent";
import FairInfoBar from "../FairInfoBar";
import { Grid } from "../Grid";
import axios from "axios";
import {
	handleGenericErrors,
	handleGenericSuccess,
} from "../../utils/ErrorUtility";
import { updateUserLoginStatus, updateUserAccountId, updateUserFairEnd, updateUserFairId, updateUserFairStart, updateUserFlyerCount, updateUserSchoolName, updatePageType } from "../../DumbleData";

interface Props {
	language: string;
}

export type OrganizationData = {
	name: string;
	address: {
		line1: string;
		city: string;
		province: string;
		provinceCode: string;
		postalCode: string;
	};
	bookfairAccountId: string;
};

export type FairInfoData = {
	id: string;
	name: string;
	start: string;
	end: string;
	setup: string;
	coaStatus: string;
	coaAccepted: boolean;
	coaAcceptedDate: string;
	requestDateChangePending: boolean;
	fliers: {
		fifthAndBelow: number;
		sixthAndAbove: number;
	};
	planningKitShipped: boolean;
	type: string;
	shippingDate: string;
	bogoFair: boolean;
};

export type ChairpersonData = {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
};

export const ConfirmYourFair = ({ language }: Props) => {
	const [data, setData] = useState<any>();
	const [coaConfirmedOpen, setCoaConfirmedOpen] = useState(false);

	// GET request
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/coa`,{withCredentials: true,})
			.then((res) => {
				if(res.status === 200) {
					setData(res.data);
					updateUserLoginStatus(true);
					updatePageType("setup");
					updateUserFairId(res.data.fairInfo.id);
					updateUserAccountId(res.data.organization.bookfairAccountId);
					updateUserSchoolName(res.data.fairInfo.name);
					updateUserFairStart(res.data.fairInfo.start);
					updateUserFairEnd(res.data.fairInfo.end);
					updateUserFlyerCount(res.data.fairInfo.fliers.fifthAndBelow, res.data.fairInfo.fliers.sixthAndAbove);
					window.scrollTo({
						top: 0,
						left: 0
					});
				} else {
					handleGenericSuccess(res);
				}
			})
			.catch((error) => {
				handleGenericErrors(error);
			});
	}, []);
	if (!data) {
		return null;
	}
	return (
		<>
			<Grid>
				<FairInfoBar
					enableSwitch={data.user.enableSwitch}
					language={language}
					fairInfoData={data.fairInfo}
					organizationData={data.organization}
					pageType={coaConfirmedOpen ? "coa-confirmed" : "coa"}
				/>
				<ConfirmYourFairContent
					language={language}
					fairInfoData={data.fairInfo}
					chairpersonData={data.chairperson}
					organizationData={data.organization}
					coaConfirmedOpen={coaConfirmedOpen}
					setCoaConfirmedOpen={setCoaConfirmedOpen}
				/>
			</Grid>
		</>
	);
};
